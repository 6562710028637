.custom-color-section {
  display: flex;
  flex-direction: column;
}

.color-picker-header {
  margin-bottom: 1rem;
}

.color-picker-info {
  margin-bottom: 1rem;
}

/* Light mode adjustments */
body.light-mode .color-picker-info {
  background: var(--background-light);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .color-picker-info {
    margin-top: 0.5rem;
    padding: 0.75rem;
    width: 100%;
    position: relative;
    z-index: 1000;
  }

  .color-picker-menu {
    position: fixed;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    width: auto;
    max-height: 80vh;
    overflow-y: auto;
    background: var(--background-dark);
  }

  body.light-mode .color-picker-menu {
    /* background: rgba(0, 0, 0, 0.05); */
    color: var(--text-dark);
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .color-picker-info {
    padding: 0.5rem;
    font-size: 0.85rem;
  }
}

.toggle-picker-btn {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: var(--background-dark);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1001;
}

.toggle-btn-content {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.current-color {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid var(--border-color);
}

.toggle-btn-text {
  flex-grow: 1;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--text-light);
}

.close-icon {
  color: var(--text-light);
  font-size: 1rem;
}

/* Light mode styles */
body.light-mode .toggle-picker-btn {
  background: var(--background-light);
}

body.light-mode .toggle-btn-text,
body.light-mode .close-icon {
  color: var(--text-dark);
}

/* Hover effects */
.toggle-picker-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.toggle-picker-btn:active {
  transform: translateY(0);
}

/* Responsive styles */
@media (max-width: 768px) {
  .toggle-picker-btn {
    padding: 8px 12px;
  }
  
  .current-color {
    width: 20px;
    height: 20px;
  }
  
  .toggle-btn-text {
    font-size: 0.85rem;
  }
}

.color-picker-menu {
  background: var(--text-dark);
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
}

.light-mode .color-picker-menu {
  background: var(--background-light);
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.color-preview-section {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
}

.large-color-preview {
  width: 80px;
  height: 80px;
  border-radius: 16px;
  transition: all 0.3s ease;
}

.color-info {
  flex: 1;
}

.color-hex {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-light);
  margin-bottom: 0.75rem;
}

.light-mode .color-hex , .light-mode .color-picker-menu .preset-colors label, .light-mode .color-input-label {
  color: var(--text-dark);
}

.brightness-indicator {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.brightness-bar {
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.brightness-fill {
  height: 100%;
  background: var(--primary-color);
  transition: width 0.3s ease;
}

.color-input-section {
  margin-bottom: 2rem;
}

.color-input-label {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-size: 0.9rem;
  color: var(--text-light);
  opacity: 0.7;
}

.color-input-wrapper {
  position: relative;
  height: 48px;
}

.color-input {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.color-input-preview {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 8px;
  font-family: monospace;
  font-size: 0.9rem;
  color: var(--text-light);
  transition: all 0.3s ease;
}

.preset-colors {
  margin-bottom: 2rem;
}

.preset-colors label {
  display: block;
  font-size: 0.9rem;
  color: var(--text-light);
  opacity: 0.7;
  margin-bottom: 1rem;
}

.preset-colors-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.75rem;
}

.preset-color-btn {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.preset-color-btn:hover {
  transform: scale(1.1);
}

.color-picker-footer {
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.color-warning {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-light);
  font-size: 0.9rem;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}

.info-icon {
  color: var(--primary-color);
  flex-shrink: 0;
  font-size: 1.1rem;
}

/* Light mode adjustments */
body.light-mode .color-warning {
  background: rgba(0, 0, 0, 0.05);
}

body.light-mode .color-warning span {
  color: var(--text-dark);
}

.active-theme-indicator {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  color: var(--text-light);
  margin-bottom: 1rem;
}

.active-theme-indicator .check-icon {
  margin-left: auto;
  color: var(--primary-color);
  font-size: 1rem;
}

/* Light mode adjustments */
body.light-mode .active-theme-indicator {
  background: rgba(0, 0, 0, 0.05);
  color: var(--text-dark);
}

body.light-mode .active-theme-indicator .check-icon {
  color: var(--primary-color);
}

body.light-mode .info-icon {
  color: var(--primary-color);
}

body.light-mode .color-warning span {
  color: var(--text-dark);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .color-picker-menu {
    position: fixed;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    z-index: 1000;
  }

  .preset-colors-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  
  .large-color-preview {
    width: 60px;
    height: 60px;
  }
  
  .color-hex {
    font-size: 1.25rem;
  }

  .color-picker-info {
    margin-top: 0.5rem;
    padding: 0.75rem;
  }
  
  .color-warning,
  .active-theme-indicator {
    font-size: 0.85rem;
    padding: 0.6rem 0.75rem;
  }
}

.color-accordion {
  border-radius: 8px;
  background: var(--background-dark);
  overflow: hidden;
}

.accordion-trigger {
  width: 100%;
  padding: 1rem;
  border: none;
  background: transparent;
  cursor: pointer;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.accordion-trigger:hover {
  background: rgba(255, 255, 255, 0.05);
}

.accordion-trigger.expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
}

.accordion-header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-light);
}

.color-preview {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid var(--border-color);
}

.accordion-icon {
  margin-left: auto;
  width: 20px;
  height: 20px;
  position: relative;
  transition: transform 0.3s ease;
}

.accordion-icon::before,
.accordion-icon::after {
  content: '';
  position: absolute;
  background: var(--text-light);
  border-radius: 1px;
}

.accordion-icon::before {
  width: 2px;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}

.accordion-icon::after {
  width: 12px;
  height: 2px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.expanded .accordion-icon::before {
  transform: translate(-50%, -50%) rotate(90deg);
}

.accordion-content {
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.accordion-content-inner {
  padding: 1.5rem;
}

/* Light mode styles */
body.light-mode .color-accordion {
  background: var(--background-light);
}

body.light-mode .accordion-trigger:hover {
  background: rgba(0, 0, 0, 0.05);
}

body.light-mode .accordion-header-content {
  color: var(--text-dark);
}

body.light-mode .accordion-icon::before,
body.light-mode .accordion-icon::after {
  background: var(--text-dark);
}

/* Responsive styles */
@media (max-width: 768px) {
  .color-accordion {
    margin: 0.5rem;
  }

  .accordion-trigger {
    padding: 0.75rem;
  }

  .accordion-content-inner {
    padding: 1rem;
  }

  .color-preview {
    width: 20px;
    height: 20px;
  }
}

/* Animation classes */
.accordion-content {
  will-change: height;
}

.accordion-trigger {
  will-change: border-radius;
}

/* Smooth animation for content */
.accordion-content-inner {
  transform-origin: top;
  opacity: 0;
  animation: slideDown 0.3s ease forwards;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add transition for color preview */
.color-preview {
  transition: transform 0.2s ease;
}

.accordion-trigger:hover .color-preview {
  transform: scale(1.1);
}




