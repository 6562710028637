:root {
  /* Other root variables can stay */
}

.projects-container {
  padding: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
}

.section-title {
  color: var(--text-light);
  margin-bottom: 1rem;
}

body.light-mode .section-title {
  color: var(--text-dark);
}

.project-description {
  color: var(--text-light);
  opacity: 0.8;
}

body.light-mode .project-description {
  color: var(--text-dark);
}

/* Left Column Styles */
.contact-me.sticky-section {
  position: sticky;
  top: 80px; /* Adjust this value based on your header height */
  height: fit-content;
  padding-right: 2rem;
  align-self: flex-start;
  max-height: calc(100vh - 100px); /* Prevents content from extending beyond viewport */
  overflow-y: auto; /* In case content is too long */
}

/* Parent row styles to enable sticky behavior */
.ar-section-padding.container > .row {
  display: flex;
  position: relative;
}

/* Ensure projects container scrolls independently */
.projects-container {
  min-height: 100vh;
  overflow: visible;
}

.featured-title {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, var(--primary-color), #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featured-subtitle {
  font-size: 1.1rem;
  color: var(--text-light);
  margin-bottom: 2.5rem;
  line-height: 1.6;
  opacity: 0.8;
}

.project-filters {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 0.6rem 1.2rem;
  border: 1px solid var(--primary-color);
  background: transparent;
  color: var(--text-light);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  /* margin: 0.5rem; */
  font-size: 0.9rem;
}

body.light-mode .filter-btn {
  color: var(--text-dark);
}

.filter-btn.active {
  background: var(--primary-color);
  color: white !important;
}

.filter-btn:hover:not(.active) {
  background: rgba(var(--primary-color-rgb), 0.1);
}

/* Right Column Styles */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  gap: 2rem;
  padding: 1rem;
}

.project-card {
  background: rgba(var(--primary-color-rgb), 0.02);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
}

body.light-mode .project-card {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05),
              0 2px 4px -1px rgba(0, 0, 0, 0.03);
}

.project-card:hover {
  transform: translateY(-5px);
  border-color: var(--primary-color);
}

.project-image {
  height: 200px;
  position: relative;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.project-links {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-card:hover .project-links {
  opacity: 1;
}

.project-card:hover .project-image img {
  transform: scale(1.05);
}

.project-link-btn {
  padding: 0.8rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 25px;
  color: var(--text-light);
  background: rgba(var(--primary-color-rgb), 0.2);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.project-link-btn:hover {
  background: var(--primary-color);
  transform: translateY(-2px);
}

.project-footer {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

.project-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}

.project-action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-light);
  text-decoration: none;
  font-size: 0.9rem;
  opacity: 0.8;
  transition: all 0.3s ease;
}

body.light-mode .project-action-btn {
  color: var(--text-dark);
}

.project-action-btn:hover {
  opacity: 1;
  color: var(--primary-color);
  transform: translateX(5px);
}

.project-action-btn svg {
  font-size: 1.1rem;
}

.project-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
}

.project-main-content {
  flex-grow: 1;
}

.project-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: var(--text-light);
}

body.light-mode .project-title {
  background: linear-gradient(45deg, var(--primary-color), #000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-description {
  color: var(--text-light);
  opacity: 0.8;
  line-height: 1.6;
  margin-bottom: 1rem;
}

body.light-mode .project-description {
  color: var(--text-dark);
}

.tech-stack {
  margin-top: auto;
  padding-top: 1rem;
}

.tech-badge {
  padding: 0.5rem 1rem;
  border: 1px solid rgba(var(--primary-color-rgb), 0.3);
  border-radius: 15px;
  font-size: 0.85rem;
  color: var(--text-light);
  background: rgba(var(--primary-color-rgb), 0.1);
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
  margin-right: 0.5rem;
}

body.light-mode .tech-badge {
  color: var(--text-dark);
  background: rgba(var(--primary-color-rgb), 0.05);
}

.tech-badge:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  background: rgba(var(--primary-color-rgb), 0.2);
}

/* Add a subtle animation for cards appearing */
.project-card {
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 991px) {
  .contact-me.sticky-section {
    position: relative;
    top: 0;
    padding-right: 0;
    max-height: none;
    overflow: visible;
    margin-bottom: 2rem;
  }

  .projects-left {
    position: relative;
    top: 0;
    padding-right: 0;
    margin-bottom: 3rem;
    text-align: center;
  }

  .featured-title {
    font-size: 2.5rem;
  }
  
  .filter-btn {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }

  .projects-container {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .projects-container {
    padding: 2rem 1rem;
  }

  .featured-title {
    font-size: 2rem;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }
}
.view-project-btn {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.view-project-btn:hover {
  transform: translateX(5px);
}

.view-project-btn svg {
  transition: transform 0.3s ease;
}

.view-project-btn:hover svg {
  transform: translateX(3px);
}

.project-card {
  display: flex;
  flex-direction: column;
}

.project-image {
  height: 200px;
}

.project-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
}

.tech-stack {
  margin: 0.5rem 0;
}

.project-counts {
  margin: 2rem 0;
}

.total-count {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, var(--primary-color), #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.category-counts {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.category-count {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.count-number {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
}

.count-label {
  font-size: 0.9rem;
  color: var(--text-light);
  opacity: 0.8;
}

body.light-mode .count-label {
  color: var(--text-dark);
}

.expertise-card {
  background: rgba(var(--primary-color-rgb), 0.05);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.3s ease;
  height: 100%;
}

.expertise-card:hover {
  border-color: var(--primary-color);
}

.card-col {
  margin-bottom: 2rem;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .card-col {
    margin-bottom: 1rem;
  }
  
  .expertise-card {
    padding: 1.5rem;
  }
}
/* Responsive design */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr; /* Single column on mobile */
    gap: 1.5rem;
  }
}

/* Add these new styles */
.project-badge {
  position: absolute;
  top: 1rem;
  left: 1rem;
  /* background: rgba(255, 255, 255, 0.95); */
  padding: 0.35rem 0.7rem;
  border-radius: 20px;
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--text-light);
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transform: translateY(-20px);
  opacity: 0;
  animation: badgeSlideDown 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: 0.2s;
}

.project-badge.new {
  background: linear-gradient(45deg, #00c853, #64dd17);
  color: white;
}

.project-badge.featured {
  background: linear-gradient(45deg, var(--primary-color), #9c27b0);
  color: white;
}

.project-badge.updated {
  background: linear-gradient(45deg, #2196f3, #00bcd4);
  color: white;
}

.badge-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: currentColor;
  animation: badgePulse 2s infinite;
}

@keyframes badgeSlideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes badgePulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Adjust project image to accommodate badge */
.project-image {
  position: relative;
}
