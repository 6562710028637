.profile-page {
  padding: 50px 0;
  background-color: #f4f4f4;
}

.background-image-amrit {
  display: block !important;
  position: relative;
  height: 101vh;
  background-image: linear-gradient(
      to right,
      var(--primary-color) -100%,
      rgba(255, 255, 255, 0) 50%
    ),
    url("../assets/amrit-image01.png");
  background-size: initial;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

body.light-mode .background-image-amrit {
  /* background-color: #d6d2d3; */
  color: #f4f4f4;
}

/* Adjust for iOS devices */
@media screen and (max-width: 1024px) {
  .background-image-amrit {
    background-attachment: scroll; /* Remove fixed attachment for mobile */
  }
  .background-primary {
    background: var(--background-primary);
    padding: 0.5rem;
    opacity: 0.75;
  }
}

.background-image-amrit::before {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  /* border-radius: 30px 100px; */
}

.min-height-100 {
  height: 90vh;
  display: flex;
  align-items: end;
  padding-bottom: 1rem;
  z-index: 1;
}

.background-image-amrit > img {
  border-radius: 20px 100px;
  height: 100%;
}
.marquee-profile {
  gap: 20px;
  font-weight: 500;
  color: inherit !important;
}
.marquee .marquee-icons {
  border-radius: 30px;
  height: 40px;
  width: 100%;
  border: 1px solid var(--button-primary-color);
  box-shadow: 0px 0px 7px 1px var(--button-primary-color);
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.marquee .marquee-icons img {
  height: 20px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
}

.remove-focus:focus {
  outline: 0 !important;
}

.profile-icons,
.view-resume {
  z-index: 1;
  padding-top: 5vh;
  align-items: center;
}

.circle-icon {
  border: 1px solid var(--primary-color);
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.ar-step-count {
  font-weight: 800;
  font-size: 96px;
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--button-primary-color),
    var(--light-primary-gradient-color)
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.count-fontsize {
  font-size: 24px !important;
}


.profile-icons .social-media-icon {
  border: 1px solid var(--primary-color);
}
.experience-timeline {
  position: relative;
  padding: 20px 0;
}

.experience-card {
  background: var(--card-background, rgba(255, 255, 255, 0.02));
  border: 1px solid var(--border-color);
  border-radius: 30px 60px;
  padding: 24px;
  margin-bottom: 32px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.experience-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.hover-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 16px;
}

.experience-card:hover .hover-details {
  opacity: 1;
}

.details-btn, .company-site-btn {
  padding: 0.8rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 25px;
  color: var(--text-light);
  background: rgba(var(--primary-color-rgb), 0.2);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 0;
  font-size: 16px;
}

.company-site-btn {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.details-btn:hover, .company-site-btn:hover {
  background: var(--primary-color);
  transform: translateY(-2px);
}

.company-site-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.experience-card:hover .details-btn,
.experience-card:hover .company-site-btn {
  transform: translateY(0);
  opacity: 1;
}

/* Stagger the animation of buttons */
.experience-card:hover .company-site-btn {
  transition-delay: 0.1s;
}

.details-btn svg {
  transition: transform 0.3s ease;
}

.experience-card:hover .details-btn svg {
  transform: translateX(4px);
}

.company-info {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.company-logo {
  width: 48px;
  height: 48px;
  border-radius: 12px 30px !important;
  background: white;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.company-meta {
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.company-link {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--primary-color);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.company-link:hover {
  color: var(--primary-color-hover);
}

.skills-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.skill-tag {
  background: rgba(var(--primary-color-rgb), 0.1);
  color: var(--primary-color);
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
}

@media (max-width: 768px) {
  .experience-card {
    padding: 16px;
  }

  .company-meta {
    flex-direction: column;
    gap: 8px;
  }

  .position-title {
    font-size: 1.1rem;
  }
}

.hero-content {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.title-container {
  margin-bottom: 2rem;
}

.role-text {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(to right, var(--primary-color), #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tagline {
  font-size: 2rem;
  line-height: 1.3;
  margin-top: 1rem;
}

.description {
  font-size: 1.1rem;
  line-height: 1.6;
  opacity: 0.8;
  margin-bottom: 2.5rem;
}

.cta-container {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-bottom: 3rem;
}

.primary-button {
  text-decoration: none;
  transition: transform 0.3s ease;
}

.primary-button:hover {
  transform: translateY(-2px);
}

.download-resume {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-light);
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 25px;
  transition: all 0.3s ease;
}

.download-resume:hover {
  background: var(--primary-color);
  transform: translateY(-2px);
}

.social-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.social-icon {
  color: var(--text-light);
  transition: all 0.3s ease;
  position: relative;
  padding: 0.5rem;
}

.social-icon:hover {
  color: var(--primary-color);
  transform: translateY(-2px);
}

.social-icon::after {
  content: attr(title);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
}

.social-icon:hover::after {
  opacity: 1;
}

@media (max-width: 768px) {
  .hero-content {
    padding: 1rem;
    text-align: center;
  }

  .role-text {
    font-size: 2rem;
  }

  .tagline {
    font-size: 1.5rem;
  }

  .cta-container {
    flex-direction: column;
    gap: 1rem;
  }

  .social-links {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.download-icon {
  font-size: 0.75rem;
  opacity: 0.8;
}

.award-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: rgba(var(--primary-color-rgb), 0.1);
  color: var(--primary-color);
  font-size: 0.875rem;
  font-weight: 500;
}

.badge-icon {
  font-size: 1rem;
}

/* Dark Mode Adjustments */
body.dark-mode .award-card {
  background: rgba(255, 255, 255, 0.03);
}

body.dark-mode .award-card:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

/* Modal Base */
.resume-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  padding: 2rem;
  animation: fadeIn 0.3s ease;
}

.resume-modal-content {
  background: var(--background-dark);
  border-radius: 24px;
  box-shadow: 0 -15px 15px rgba(255, 255, 255, 0.05),
    inset 0 -15px 15px rgba(255, 255, 255, 0.1),
    0 15px 15px rgba(0, 0, 0, 0.5),
    inset 0 15px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  height: auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  animation: slideUp 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Modal Header */
.resume-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.resume-modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--text-light);
}

/* Close Button */
.resume-modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 32px;
  height: 32px;
  padding: 0;
  border: 1px solid rgba(var(--primary-color-rgb), 0.3);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: var(--text-light);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 100001;
}

.resume-modal-close:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
  transform: rotate(90deg);
}

.resume-modal-close svg {
  width: 16px;
  height: 16px;
  stroke-width: 2px;
}

/* Modal Body */
.resume-modal-body {
  flex: 1;
  overflow-y: auto;
  height: calc(80vh - 140px); /* Adjusted to account for footer */
  padding: 0;
}

/* Scrollbar Styles */
.resume-modal-body::-webkit-scrollbar {
  width: var(--scrollbar-width, 8px);
}

.resume-modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.resume-modal-body::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-dark);
  border-radius: 4px;
}

/* IFrame */
.resume-modal-body iframe {
  width: 100%;
  height: 100%;
  border: none;
  background: white;
  display: block;
  margin: 0;
  padding: 0;
}

.resume-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(17, 17, 17, 0.8);
  backdrop-filter: blur(10px);
  padding: 1rem 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

.resume-download-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1.5rem;
  background: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 30px;
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.resume-download-btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background: var(--primary-color);
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
  border-radius: 50%;
}

.resume-download-btn:hover {
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(var(--primary-color-rgb), 0.2);
}

.resume-download-btn:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.resume-download-btn svg {
  font-size: 1.1em;
  transition: transform 0.3s ease;
}

.resume-download-btn:hover svg {
  transform: translateY(2px);
}

/* Light mode adjustments */
body.light-mode .resume-actions {
  background: rgba(245, 245, 245, 0.8);
  border-top-color: rgba(0, 0, 0, 0.1);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .resume-actions {
    padding: 0.75rem 1rem;
  }

  .resume-download-btn {
    padding: 0.6rem 1.25rem;
    font-size: 0.9rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Theme Variations */
body.light-mode .resume-modal-content {
  background: var(--background-light);
}

body.light-mode .resume-modal-header h3 {
  color: var(--text-dark);
}

body.light-mode .resume-modal-close {
  background: rgba(0, 0, 0, 0.05);
  color: var(--text-dark);
}

/* Responsive Design */
@media (max-width: 768px) {
  .resume-modal-overlay {
    padding: 1rem;
  }

  .resume-modal-content {
    border-radius: 20px;
    max-height: 75vh; /* Reduced height on mobile */
    margin: 2rem 0; /* Added margin to prevent overlap */
  }

  .resume-modal-body {
    height: calc(75vh - 80px);
    max-height: 500px; /* Reduced max-height on mobile */
  }

  .resume-modal-body iframe {
    min-height: 400px; /* Reduced minimum height on mobile */
    max-height: 500px; /* Reduced maximum height on mobile */
  }
}

/* Fix for theme menu conflict */
.theme-menu {
  z-index: 99999; /* Ensure it's below the resume modal */
}

/* Ensure modal is always on top of theme menu */
body.theme-menu-open .resume-modal-overlay {
  z-index: 100000;
}

/* Social Media Icons */
.profile-icons {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
  z-index: 1; /* Lower z-index for profile icons */
}

/* Hide social media icons when modal is open */
body:has(.resume-modal-overlay) .profile-icons {
  z-index: 0;
}

.social-media-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(var(--primary-color-rgb), 0.1);
  color: var(--text-light);
  transition: all 0.3s ease;
  text-decoration: none;
  position: relative;
  z-index: 1; /* Lower z-index for icons */
}

.social-media-icon:hover {
  background: var(--primary-color);
  transform: translateY(-2px);
}

/* Modal Styles */
.resume-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  padding: 2rem;
  animation: fadeIn 0.3s ease;
}

.resume-modal-content {
  background: var(--background-dark);
  border-radius: 24px;
  box-shadow: 0 -15px 15px rgba(255, 255, 255, 0.05),
    inset 0 -15px 15px rgba(255, 255, 255, 0.1),
    0 15px 15px rgba(0, 0, 0, 0.5),
    inset 0 15px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 900px;
  max-height: 95vh; /* Increased from 80vh */
  height: auto;
  overflow: hidden;
  position: relative;
  animation: slideUp 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.resume-modal-body {
  padding: 1.5rem;
  overflow-y: auto;
  height: calc(95vh - 140px); /* Increased from 80vh, accounting for header and footer */
  max-height: 800px; /* Increased from 600px */
}

.resume-modal-body iframe {
  width: 100%;
  height: 100%;
  min-height: 600px; /* Increased from 500px */
  max-height: 800px; /* Increased from 600px */
  border: none;
  background: white;
}

/* Close Button */
.resume-modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 32px;
  height: 32px;
  padding: 0;
  border: 1px solid rgba(var(--primary-color-rgb), 0.3);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: var(--text-light);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 100001;
}

.resume-modal-close:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
  transform: rotate(90deg);
}

/* Responsive Design */
@media (max-width: 768px) {
  .resume-modal-overlay {
    padding: 1rem;
  }

  .resume-modal-content {
    border-radius: 20px;
    max-height: 85vh; /* Increased from 75vh */
    margin: 2rem 0;
  }

  .resume-modal-body {
    height: calc(85vh - 80px); /* Increased from 75vh */
    max-height: 700px; /* Increased from 500px */
  }

  .resume-modal-body iframe {
    min-height: 500px; /* Increased from 400px */
    max-height: 700px; /* Increased from 500px */
  }

  .profile-icons {
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.75rem;
  }

  .social-media-icon {
    width: 35px;
    height: 35px;
  }
}

/* Z-index hierarchy fixes */
.profile-center {
  position: relative;
  z-index: 1;
}

.view-resume {
  position: relative;
  z-index: 1;
}

/* Ensure modal is always on top */
body.modal-open {
  overflow: hidden;
}

body.modal-open .resume-modal-overlay {
  z-index: 100000;
}

/* Fix for theme menu conflict */
.theme-menu {
  z-index: 99999;
}

/* Additional fixes for profile page elements */
.footer-bottom-icons {
  position: relative;
  z-index: 1;
}

.profile-section {
  position: relative;
  z-index: 1;
}

.download-link {
  color: var(--primary-color);
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.download-link:hover {
  opacity: 1;
  background: rgba(var(--primary-color-rgb), 0.1);
  transform: translateY(-2px);
}

/* Dark mode specific styles */
.dark-mode .achievement-card {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .achievement-card:hover {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .achievement-cards {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0.5rem;
  }

  .achievement-card {
    padding: 1.25rem;
  }

  .achievement-icon {
    width: 40px;
    height: 40px;
  }

  .achievement-icon .icon {
    font-size: 1.25rem;
  }

  .achievement-title {
    font-size: 1rem;
  }
}

/* Replace the previous display: none with opacity transition */
@media (max-width: 768px) {
  body.resume-modal-open .header,
  body.resume-modal-open .footer-mobile {
    opacity: 0.1;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  /* Keep the rest of the mobile modal styles */
  body.resume-modal-open {
    overflow: hidden;
  }

  body.resume-modal-open .resume-modal-overlay {
    padding: 0;
  }

  body.resume-modal-open .resume-modal-content {
    height: 100vh;
    max-height: 100vh;
    margin: 0;
    border-radius: 0;
  }

  body.resume-modal-open .resume-modal-body {
    height: calc(100vh - 60px);
  }

  body.resume-modal-open .resume-modal-body iframe {
    height: 100%;
    min-height: unset;
    max-height: unset;
  }
}


