/* Define color variables for easy management */
:root {
  --background-light: #ebe8e4;
  --background-dark: #0b0b0d;
  --background-primary: #d55882;
  --primary-color: #d55882;

  --border-color: #8b5a6c;
  --font-primary-color: #d55882;
  --button-primary-color: #d55882;

  --navbar-padding: 0.75em 1.25rem;
  --navbar-gap: 1.75rem;

  --icon-size: 2.25rem;
  --font-size-nav: 1rem;

  --link-color: #695a60;
  --link-hover-color: #be6284;
  --link-active-color: #d55882;

  --text-primary: #d55882;
  --text-light: #ebe8e4;
  --text-dark: #171515;
  --light-grey: #7e7377;

  --footer-bg: #ff000011;
  --light-primary-gradient-color: #ff80a6;

  --scrollbar-thumb-dark: #363134;
  --scrollbar-thumb-light: #b2a0a0;
  --scrollbar-width: 12px;

  --color-transition: all 0.3s ease;
}

/* Add transition properties to elements that use theme colors */
*, *::before, *::after {
  transition: var(--color-transition);
}

/* Specific transitions for color-sensitive elements */
button,
a,
.theme-option,
.color-preview,
.theme-menu,
.custom-cursor,
[class*="primary-color"],
[class*="background-color"] {
  transition: var(--color-transition);
}

/* Exclude certain properties from transition */
.custom-cursor {
  transition: transform 0.2s ease, var(--color-transition);
}

/* Disable transitions for elements where it might cause performance issues */
.no-transition {
  transition: none !important;
}

@media (prefers-reduced-motion: reduce) {
  *, *::before, *::after {
    transition-duration: 0.001ms !important;
  }
}

/* Default light mode styles */
body {
  background-color: var(--background-dark);
  color: var(--text-light);
  transition: background-color 1s ease-in-out, color 1s ease,
    border-color 0.25s ease, box-shadow 0.25s ease, opacity 0.25s ease;
  overflow-x: hidden;
  font-family: "Inconsolata", monospace;
  background-image: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.03) 15%,
    transparent 1px
  );

  background-size: 20px 20px;
  background-position: 0 0;
  z-index: 22;
}

/* Dark mode styles */
body.light-mode {
  background-color: var(--background-light);
  color: var(--text-dark);
  transition: background-color 1s ease-in-out, color 1s ease,
    border-color 0.25s ease, box-shadow 0.25s ease, opacity 0.5s ease;
  /* background-image: radial-gradient(circle, #ffffff 1px, transparent 1px); */
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.03) 15%,
    transparent 1px
  );

  background-size: 20px 20px;
  background-position: 0 0;
}

body .modal-content {
  height: 90vh;
  background-color: var(--background-dark) !important;
  color: var(--text-light) !important;
  box-shadow: 0 -15px 15px rgba(255, 255, 255, 0.05),
    inset 0 -15px 15px rgba(255, 255, 255, 0.1), 0 15px 15px rgba(0, 0, 0, 0.5),
    inset 0 15px 15px rgba(0, 0, 0, 0.2);
}

body.light-mode .modal-content {
  background-color: var(--background-light) !important;
  color: var(--text-dark) !important;
  transition: background-color 1s ease, color 0.25s ease,
    border-color 0.25s ease, box-shadow 0.5s ease, opacity 0.5s ease;
}

/* Base scrollbar styles */
*::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-dark);
  border-radius: 10px;
  border: 3px solid var(--background-dark);
  transition: all 2s ease;
}

* .light-mode::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-light);
  border: 3px solid var(--background-light);
  border-radius: 10px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

body.light-mode *::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-light);
  border: 3px solid var(--background-light);
  border-radius: 10px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

::-moz-selection {
  color: var(--text-light);
  background: var(--border-color);
}

::selection {
  color: var(--text-light);
  background: var(--border-color);
}

.theme-toggle-btn {
  background: var(--text-dark);
  border: 1px solid var(--text-primary) !important;
  padding: 8px;
  border-radius: 20px 0 0 20px;
  position: relative;
  border-right: none !important;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns FaCog to the right */
  min-width: 50px;
  gap: 10px;
}

/* Ensures FaCog is always visible */
.theme-toggle-btn .cog-icon {
  font-size: 18px;
  color: var(--text-primary);
  transition: transform 0.3s ease-in-out;
}

/* Sliding text animation */
.theme-toggle-btn::before {
  content: "";
  position: absolute;
  right: 40px; /* Moves text left of the FaCog */
  color: var(--text-light) !important;
  opacity: 0;
  white-space: nowrap;
  transform: translateX(-20px);
}

.color-picker::before {
  background: red;
}
.theme-menu {
  width: fit-content;
  border: 1px solid var(--text-dark);
  border-radius: 10px 20px;
  position: absolute;
  right: 30px;
  z-index: 33333;
  min-width: 300px;
  background-color: var(--text-dark);
  border: 1px solid var(--primary-color);

  /* Smooth transition settings */
  /* opacity: 0; */
  transform: translateX(500px);
  visibility: hidden;
  pointer-events: none; 
  transition: all 0.8s ease, transform 0.8s ease;
}

.theme-menu.show {
  opacity: 1;
  transform: translateX(0px);
  visibility: visible;
  pointer-events: all; 
  max-height: 80vh;
  overflow: auto;
}

img,
.slick-slider:focus,
.slick-slide:focus {
  outline: none;
  border: none;
}

.theme-text {
  display: flex;
  align-items: center;
}

.color-flag {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 0.1px solid var(--text-light);
  margin-right: 10px;
}
.color-flag-red {
  background-color: #d55882;
}
.color-flag-green {
  background-color: #0d9274;
}

.color-flag-blue {
  background-color: #0d5075;
}

hr {
  border-color: var(--text-light);
  margin: 8px 0;
}

.section,
.container {
  z-index: 22;
}

/*  */
.ar-resume-sticky a {
  position: fixed;
  left: 0;
  top: 40%;
  background: var(--text-primary);
  color: var(--text-light) !important;
  writing-mode: vertical-rl;
  text-orientation: upright;
  padding: 1rem 0.5rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 5px;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  z-index: 44;
}
