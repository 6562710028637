.contact-info {
  margin-bottom: 1rem;
}

.contact-icon {
  color: var(--primary-color); /* Adjust according to your theme */
  margin-right: 0.5rem;
}

.contact-form {
  background-color: transparent;
  padding: 1rem;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 10px 30px;
  background: transparent;
  color: inherit !important;
}
.form-group label {
  margin-bottom: 0.5rem !important;
  font-size: 1.25rem;
}
.form-control:focus {
  background: transparent !important;
  outline: var(--font-primary-color) !important;
  border: 1px solid var(--font-primary-color) !important;
  box-shadow: 0 0 0 0.1rem rgba(203, 74, 126, 0.25) !important;
}

.form-group > span > p {
  font-size: 10px;
  color: var(--font-primary-color);
  padding: 0.05rem !important;
  text-align: end !important;
}

.display-icons .footer-bottom-icons {
  display: flex;
  gap: 20px;
}

.display-icons .footer-bottom-icons a,
.scroll-top-btn {
  border-radius: 5px 15px !important;
  height: 2rem;
  width: 2rem;
  display: inline-block !important;
  line-height: 1.8;
  /* border: 1px solid var(--border-color); */
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: var(--font-primary-color) !important;
  transition: all 0.25s ease;
}

.scroll-top-btn {
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  transition: all 0.5s ease;
}

.display-icons .footer-bottom-icons a:hover {
  background-color: var(--primary-color);
  color: var(--text-light) !important;
  padding-bottom: 0.2rem;
  transition: all 0.5s ease;
  transform: translateY(-5px);
}

.contact-me {
  padding-right: 3rem;
}

.form-control.error {
  animation: shake 0.2s ease-in;
  border-color: #cd5c5c;
}

.error {
  color: #cd5c5c !important;
  font-size: 14px !important;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}

input {
  line-height: 35px !important;
}
