.setting-div {
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 999;
}

/* Hide settings div when modal is open */
body:has(.resume-modal-overlay) .setting-div {
  z-index: 0;
}

.theme-toggle-btn {
  width: 45px;
  height: 50px;
  border-radius: 50%;
  background: var(--primary-color);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.theme-toggle-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.cog-icon {
  font-size: 20px;
  transition: transform 0.5s ease;
}

.theme-toggle-btn:hover .cog-icon {
  transform: rotate(90deg);
}

.theme-menu {
  position: fixed;
  top: 80px;
  right: 20px;
  width: 900px;
  background: var(--background-dark);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  z-index: 99999; /* Adjusted to be below resume modal */
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

/* Ensure theme menu doesn't interfere with modal */
body:has(.resume-modal-overlay) .theme-menu {
  z-index: 99999;
}

/* Light mode styles */
body.light-mode .theme-menu {
  background: var(--background-light);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.theme-menu.show {
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.theme-menu-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  background: inherit;
  border-radius: 16px 16px 0 0;
  z-index: 1;
}

/* Light mode header border */
body.light-mode .theme-menu-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.theme-menu-header h6 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--text-light);
}

/* Light mode text color */
body.light-mode .theme-menu-header h6 {
  color: var(--text-dark);
}

.theme-menu-close {
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--text-light);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  opacity: 0.7;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

/* Light mode close button */
body.light-mode .theme-menu-close {
  color: var(--text-dark);
}

.theme-menu-close:hover {
  opacity: 1;
  transform: rotate(90deg);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: white;
}

.theme-section-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  color: var(--text-light);
}

/* Light mode section titles */
body.light-mode .theme-section-title {
  color: var(--text-dark);
}

.theme-options {
  display: grid;
  gap: 0.75rem;
}

.theme-option {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.05);
}

.theme-option:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Update theme options for light mode */
body.light-mode .theme-option {
  background: rgba(0, 0, 0, 0.05);
}

body.light-mode .theme-option:hover {
  background: rgba(0, 0, 0, 0.08);
}

.theme-option.active {
  background: rgba(255, 255, 255, 0.15);
}

.theme-color-preview {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.theme-option-details {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.theme-option-label {
  /* color: var(--text-light); */
  font-size: 0.95rem;
}

.theme-option-active {
  color: var(--primary-color);
}

.theme-content {
  padding: 1.5rem;
}

.theme-layout {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Equal width columns */
  gap: 2rem;
  height: 100%;
}

.theme-presets {
  padding-right: 2rem;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%; /* Ensure full height */
}

/* Light mode adjustments */
body.light-mode .theme-presets {
  border-right-color: rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .theme-menu {
    width: 90%;
    max-width: 900px;
  }
}

@media (max-width: 768px) {
  .theme-menu {
    width: calc(100% - 40px);
    max-height: 80vh;
    top: 50%;
    transform: translate(100%, -50%);
    visibility: hidden;
    opacity: 0;
  }

  .theme-menu.show {
    transform: translate(0, -50%);
    visibility: visible;
    opacity: 1;
  }

  .theme-layout {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .theme-presets {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-right: 0;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .theme-custom {
    padding-left: 0;
  }

  body.light-mode .theme-presets {
    border-bottom-color: rgba(0, 0, 0, 0.1);
  }
}

/* Ensure color picker stays within bounds */
.custom-color-section {
  position: relative;
}

.color-picker-menu {
  position: relative;
  max-height: 400px;
  overflow-y: auto;
}

/* Add smooth scroll behavior */
.theme-content, .color-picker-menu {
  scroll-behavior: smooth;
}




