.slide_image-wrapper {
  padding: 1.25rem;
}
.slide__image-wrapper {
  background-color: var(--color-accent);
  border-radius: 12px 40px;
  min-height: 300px;
  overflow: hidden;
  width: 100%;
  box-shadow: 2px 2px 3px 1px var(--border-color);
  color: var(--link-hover-color);
  padding: 15px;
  transition: all 0.5s ease;
  cursor: grab;
}

.slide__image-wrapper:active {
  cursor: grabbing;
}

.slide__image-wrapper:hover {
  transform: scale(1.05);
}

.carousel .slick-slide {
  opacity: 0.25;
  transition: opacity 0.5s ease;
}

.carousel .slick-current {
  opacity: 1;
}

.slide__image {
  height: 70px;
  width: 70px;
  border-radius: 15px 30px;
  box-shadow: 2px 2px 3px 1px var(--border-color);
  padding: 5px;
  border: 1px solid var(--border-color);
}

.slick-prev,
.slick-next {
  top: 105% !important;
  width: 35px !important;
  height: 35px !important;
  background-color: var(--primary-color) !important;
  border-radius: 5px 15px;
  z-index: 99;
  border: 1px solid var(--primary-color);
  transition: all 0.25s ease;
}
.slick-prev:hover,
.slick-next:hover {
  /* background-color: var(--link-hover-color) !important; */
  width: 38px !important;
  height: 38px !important;
  border: 1px solid var(--primary-color);
}

.slick-dots li button:before {
  color: var(--primary-color) !important;
}

@media (min-width: 1200px) {
  .slick-prev {
    left: 82% !important;
  }
}
@media (max-width: 1200px) {
  .slick-prev {
    left: 80% !important;
  }
}
@media (max-width: 1048px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.slick-next {
  right: 5% !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: inherit !important;
  font-size: 25px !important;
  opacity: 1 !important;
  line-height: 0.6 !important;
}

.card__text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Default to single-line truncation */
  transition: max-height 0.3s ease;
  color: var(--text-light);
}

.light-mode .card__text {
  color: var(--text-dark);
}

.card__text.truncated {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.card__text.expanded {
  display: block;
  white-space: normal;
  max-height: none;
}

.view-more-btn {
  background: transparent;
  border: none;
  color: var(--link-color);
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0.5rem;
  transition: color 0.3s ease;
}

.view-more-btn:hover {
  color: var(--link-hover-color); /* Change color on hover */
}
