/* Toggle container styling */
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: transparent !important;
  padding: 0.25rem;
  border-radius: 1rem;
  border: 1.5px solid var(--border-color);
}

/* Label for the toggle */
.toggle-label {
  display: flex;
  align-items: center;
}

/* Icon styling */
.icon-toggle {
  position: absolute;
  z-index: 99;
  font-size: 0.75rem; /* Adjust the size as needed */
  color: var(--text-light); /* Icon color */
  transition: all 1s ease;

}

/* Position icons */
.moon-icon {
  right: 0.62rem;
  color: var(--text-light);
}

.sun-icon {
  left: 0.62rem;
  color: var(--text-light);
}

/* Hide icon when not active */
.hidden {
  opacity: 0;
  transition: all 0.5s ease;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}

.react-toggle-track {
  background-color: inherit;
}

.react-toggle--checked > .react-toggle-track {
  background-color: inherit;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: inherit;
}

.react-toggle:hover .react-toggle-track:hover,
.react-toggle--checked:hover .react-toggle-track:hover {
  background-color: inherit;
}

.react-toggle--checked .react-toggle-thumb {
  border: 1px solid var(--primary-color);
}

.react-toggle .react-toggle-thumb {
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
}
