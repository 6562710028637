.project-detail-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.project-detail-modal {
  background: var(--background-dark);
  border-radius: 20px;
  width: 90%;
  max-width: 1200px;
  height: 90vh;
  position: relative;
  border: 1px solid var(--border-color);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden; /* Hide scrollbars */
}

.project-detail-content {
  height: 100%;
  padding: 2rem;
  overflow-y: auto; /* Enable scrolling without showing scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.project-detail-content::-webkit-scrollbar {
  display: none;
}

.close-button {
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  background: var(--primary-color);
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.close-button:hover {
  transform: rotate(90deg) scale(1.1);
  background: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.close-button svg {
  width: 20px;
  height: 20px;
}

.project-detail-header {
  margin-bottom: 2rem;
}

.project-detail-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1.5rem;
}

.project-detail-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.project-detail-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.project-detail-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  color: white;
}

.github-btn {
  background-color: var(--primary-color);
}

.demo-btn {
  background-color: var(--primary-color);
}

.project-detail-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(var(--primary-color-rgb), 0.3);
}

.project-detail-btn svg {
  font-size: 1.2rem;
}

.detail-section {
  margin-bottom: 2rem;
}

.detail-section h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.detail-tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.detail-tech-badge {
  padding: 0.5rem 1rem;
  border-radius: 15px;
  background: var(--primary-color);
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-list {
  list-style-type: none;
  padding: 0;
}

.feature-list li {
  padding: 0.5rem 0;
  position: relative;
  padding-left: 1.5rem;
}

.feature-list li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

/* Remove navigation styles */
.project-navigation {
  display: none;
}

.nav-button {
  background: rgba(var(--primary-color-rgb), 0.9);
  color: var(--text-light);
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.nav-button:hover {
  transform: translateY(-2px);
  background: var(--primary-color);
}

.nav-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-button.disabled:hover {
  transform: none;
}

.project-detail-body {
  margin-bottom: 3rem; /* Add space for the button */
  position: relative;
}

.view-project-link {
  position: absolute;
  bottom: -2rem;
  right: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  background: var(--primary-color);
  color: var(--text-light);
  text-decoration: none;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-weight: 500;
}

.view-project-link:hover {
  transform: translateX(5px);
  box-shadow: 0 4px 12px rgba(var(--primary-color-rgb), 0.2);
}

.view-project-link svg {
  transition: transform 0.3s ease;
}

.view-project-link:hover svg {
  transform: translateX(3px);
}

/* Update light mode styles */
body.light-mode .project-detail-modal {
  background: var(--background-light);
}

/* Responsive styles */
@media (max-width: 768px) {
  .project-detail-modal {
    width: 95%;
    height: 95vh;
  }
  
  .project-detail-content {
    padding: 1.5rem;
  }
  
  .project-detail-image {
    height: 250px;
  }
  
  .project-detail-title-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .project-detail-links {
    flex-direction: column;
    width: 100%;
  }

  .project-detail-btn {
    width: 100%;
    justify-content: center;
  }
}
