/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 15px;
  z-index: 2;
  margin-top: 1rem;
}

/* Navbar visible */
.visible {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.5s ease;
}

/* Navbar hidden */
.hidden {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.5s ease;
}

.scrolled-bg {
  box-shadow: 2px 4px 20px 3px rgba(175, 71, 104, 0.24);
  border-radius: 50px 100px;
  margin-top: 1rem;
  backdrop-filter: blur(30px);
}
.hide-border {
  box-shadow: none !important;
}

/* Logo styling */
.ar-logo {
  /* color: var(--font-primary-color); */
  cursor: pointer;
  /* padding: 0 20px; */
  z-index: 0;
  /* border: 1px solid var(--primary-color); */
}
/* Individual letter styling with jumping animation */
.ar-logo .jump-letter,
.bg-name {
  display: inline-block;
  animation: jump 1s ease-in-out forwards;
  position: fixed;
  top: 10vh;
  /* left: 10%; */
  font-weight: 700 !important;
  font-size: 400px !important;
  opacity: 0.1;
  z-index: 0;
}

.font-bg {
 font-size:180px !important
}

.left-10 {
  left: 10%;
}
.right-10 {
  right: 10%;
}

.ar-logo .jump-letter-mob {
  display: inline-block;
  animation: jump 1s ease-in-out forwards;
  font-size: 28px !important;
  color: var(--font-primary-color);
  font-weight: 800;
}

/* Apply staggered animation delays */
.ar-logo .jump-letter:nth-child(1) {
  animation-delay: 0s;
}
.ar-logo .jump-letter:nth-child(2) {
  animation-delay: 0.1s;
}
.ar-logo .jump-letter:nth-child(3) {
  animation-delay: 0.2s;
}
.ar-logo .jump-letter:nth-child(4) {
  animation-delay: 0.3s;
}
.ar-logo .jump-letter:nth-child(5) {
  animation-delay: 0.4s;
}

/* Navigation list styling */
.nav-list {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: center;
  border: 1px solid var(--border-color);
  border-radius: 32px 80px;
  padding: 0.2rem;
  gap: var(--navbar-gap);
}

/* Navigation link styling */
.nav-link {
  color: var(--primary-color);
  text-decoration: none;
  font-size: var(--font-size-nav);
  background: transparent;
  padding: 0.25rem 1.5rem 0.5rem 1.5rem;
  border-radius: 20px 50px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  transition: background 0.5s ease;
}

/* Active navigation link styling */
.nav-link.active {
  color: var(--text-light);
  background: var(--link-active-color);
  padding: 0.25rem 1.5rem 0.5rem 1.5rem;
  border-radius: 20px 50px;
}

/* Hover effect for navigation links */
.nav-link:hover {
  color: var(--text-light);
}

/* Keyframes for jumping animation */
@keyframes jump {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Keyframes to stagger the animation for individual letters */
@keyframes staggeredJump {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
