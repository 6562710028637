.background-amrit {
  background-image: linear-gradient(
    to left,
    var(--primary-color) -130%,
    rgba(255, 255, 255, 0) 100%
  ), url("../assets/amrit-image01.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 80px 32px 0;
  /* background-color: var(--primary-color); */
  opacity: 0.9;
}

.ar-background-upper-footer {
  border-radius: 35px 85px;
  box-shadow: 2px 4px 10px 3px rgba(175, 71, 104, 0.24);
  border: 1px solid var(--border-color);
}

.upper-footer-left-pannel {
  padding: 2rem;
}

.footer-informations p a {
  color: inherit !important;
  text-decoration: none !important;
}

.mobile-view {
  display: flex;
  justify-content: space-between;
}
.mobile-view .nav-item {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: -2px;
}

/* Base styles for the footer */
.footer-mobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: var(--text-light);
  padding: 1rem 0.5rem;
  text-align: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 2;
  backdrop-filter: blur(50px);
  box-shadow: 2px 4px 20px 3px rgba(175, 71, 104, 0.4);
  border-radius: 20px 50px;
  transform: translate(-50%, -0%);
  left: 50%;
}

/* Responsive styles */
@media (max-width: 1080px) {
  .footer-mobile {
    bottom: 0;
    backdrop-filter: blur(50px);
    z-index: 10;
    box-shadow: 2px 4px 20px 3px rgba(175, 71, 104, 0.4);
    border-radius: 20px 20px 0 0;
    width: 100%;
  }
}
.available-for-work-bladge {
  border: 1px solid rgba(76, 175, 80, 0.3); /* Green shade for availability */
  border-radius: 10px 20px;
  padding: 0.5rem 0.75rem;
  font-size: 12px !important;
  box-shadow: 1px 2px 4px 1px rgba(76, 175, 80, 0.25); /* Subtle shadow */
  color: #4CAF50; /* Green font color for consistency */
  font-weight: bold; /* Make the text stand out */
}

/* Dot blinking animation */
.blinking-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #4CAF50; /* Green for the blinking dot */
  border-radius: 50%;
  animation: blinker 1s infinite;
}

@keyframes blinker {
  0% {
    box-shadow: 0 0 0 1px var(--font-primary-color);
  }
  50% {
    box-shadow: 0 0 0 2px rgba(255, 109, 182, 0.4);
  }
  100% {
    box-shadow: 0 0 0 3px rgba(255, 109, 182, 0.1);
  }
}

.footer-link.active {
  border-radius: 20px;
}

.phone-email-address-icon {
  border-radius: 5px 15px;
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.8;
  border: 1px solid var(--border-color);
  color: var(--font-primary-color) !important;
}

.phone-email-address-icon {
  color: var(--font-primary-color) !important;
  transition: all 0.5s ease;
  margin-bottom: -1rem;
  margin-left: 0.5rem !important;
  backdrop-filter: blur(50px) !important;
}

.phone-email-address-icon:hover {
  background-color: var(--primary-color);
  color: var(--text-light) !important;
  transition: all 0.5s ease;
}

.phone-email-address-div .link-span {
  padding: 2rem 1rem 1rem 1rem;
  border: 1px solid var(--font-primary-color);
  border-radius: 10px 25px;
  display: block !important;
  color: var(--primary-color);
  text-decoration: none !important;
}