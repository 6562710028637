.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
  animation: fadeIn 0.3s ease;
}

.modal-container {
  background: var(--background-color);
  border-radius: 24px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.4);
  width: 100%;
  max-width: 900px;
  max-height: 85vh;
  overflow: hidden;
  position: relative;
  animation: slideUp 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 1.5rem;
  position: relative;
  border-bottom: 1px solid rgba(var(--text-rgb), 0.1);
  background: linear-gradient(
    145deg,
    rgba(var(--primary-color-rgb), 0.1),
    transparent
  );
}

.company-banner {
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
}

.company-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 20px;
  padding: 16px;
  background: white;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.company-logo:hover {
  transform: scale(1.05);
}

.company-info {
  flex: 1;
}

.company-name {
  font-size: 2.2rem;
  font-weight: 700;
  margin: 0;
}

.job-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--text-color);
  margin: 0.75rem 0;
}

.timeline {
  display: flex;
  gap: 1.5rem;
  font-size: 1rem;
  color: var(--text-secondary);
  margin-top: 0.2rem;
}

.timeline-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.timeline-icon {
  stroke: currentColor;
  stroke-width: 2;
  fill: none;
}

.modal-close-btn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  border: 1px solid var(--primary-color);
  color: var(--text-color);
  padding: 0.75rem;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 1001;
  width: 40px;
  height: 40px;
}

.modal-close-btn:hover {
  background: var(--primary-color);
  color: white;
  transform: rotate(90deg);
  border-color: var(--primary-color);
}

.modal-body {
  padding: 2.5rem;
  overflow-y: auto;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.experience-details {
  color: var(--text-color);
}

.responsibility-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.responsibility-item {
  position: relative;
  padding: 0.25rem 1rem;
  margin-bottom: 0.5rem;
  background: rgba(var(--text-rgb), 0.03);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.responsibility-item:hover {
  background: rgba(var(--text-rgb), 0.05);
  transform: translateX(5px);
}

.responsibility-item::before {
  content: "•";
  color: var(--primary-color);
  position: absolute;
  left: 0.5rem;
}

.empty-state {
  padding: 3rem;
  text-align: center;
  color: var(--text-secondary);
}

/* Scrollbar styling */
.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-track {
  background: rgba(var(--text-rgb), 0.05);
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb {
  background: rgba(var(--text-rgb), 0.2);
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--text-rgb), 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-overlay {
    padding: 0.5rem;
  }

  .modal-container {
    border-radius: 20px;
  }

  .modal-header {
    padding: 0.75rem;
  }

  .company-banner {
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
  }

  .company-logo {
    width: 60px;
    height: 60px;
    padding: 2px !important;
  }

  .company-name {
    font-size: 1.25rem;
  }

  .timeline {
    flex-direction: column;
    gap: 0.5rem;
  }

  .date-range {
    font-size: 0.9rem;
  }
  .experience-duration {
    font-size: 0.8rem;
    width: 100%;
    text-align: left;
    color: var(--text-secondary);
  }

  .job-title {
    font-size: 1rem;
  }


  .modal-body {
    padding: 0.75rem;
  }
}
