.ar-main-heading {
  padding-top: 3rem;
}
.ar-primary-text {
  font-size: 3rem;
}

.ar-highlighted-text {
  color: var(--font-primary-color);
}
.ar-add-seperator {
  height: 0.15px;
  width: 100%;
  background-color: var(--font-primary-color);
  display: flex;
  align-items: center;
}

.ar-link-lists span {
  color: var(--link-color);
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 0.25em;
  transition: font-weight 1s ease;
}

.ar-link-lists .social-media-icon {
  height: 2rem;
  width: 2rem;
  display: inline-block !important;
  line-height: 1.8;
  border: 1px solid var(--border-color);
  color: var(--link-color) !important;
}
.button-anchor-tag {
  text-decoration: none !important;
}

.ar-link-lists span > a {
  color: var(--link-color) !important;
  text-decoration: none !important;
}

.ar-home-social-media span > a {
  display: flex;
  justify-content: center;
  gap: 0.6rem;
  font-size: 1.2rem;
  padding-right: 1rem;
  font-weight: 400;
}

.ar-link-lists span > a:hover {
  color: var(--font-primary-color) !important;
}

.ar-link-lists span:hover {
  color: var(--font-primary-color) !important;
  font-size: 1rem;
  transition: font-weight 1s ease;
}

.way-icon-size {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  animation: wave 1.5s infinite;
  display: inline-block;
  transform-origin: 60% 70%;
}

.key-strenths .marquee {
  padding: 2rem;
  backdrop-filter: blur(5px);
  color: var(--link-color);
}

.key-strenths .marquee span {
  font-size: 3rem;
  padding-left: 2rem;
}

.basic-info,
.expertise-card {
  padding: 2rem;
  border-radius: 32px 80px;
  border: 1px solid var(--border-color);
  box-shadow: 0 -15px 15px rgba(255, 255, 255, 0.05),
    inset 0 -15px 15px rgba(255, 255, 255, 0.05),
    0 15px 15px rgba(0, 0, 0, 0.05), inset 0 15px 15px rgba(0, 0, 0, 0.05);
  filter: grayscale(0.5);
  transition: all 0.5s ease;
}
.basic-info:hover,
.expertise-card:hover {
  filter: grayscale(0);
  transition: all 0.5s ease;
  box-shadow: 0 5px 15px 1px rgba(175, 71, 104, 0.14);
}
.bio-image {
  /* background: url("../assets/amrit-image02-1.png"); */
  background-image: linear-gradient(
      to left,
      var(--primary-color) -130%,
      rgba(255, 255, 255, 0) 40%
    ),
    url("../assets/amrit-image02-1.png");
  background-repeat: no-repeat;
  background-size: inherit;
  border-radius: 15px 70px;
  background-position: 10% 20%;
}

.professional-laptop-image {
  background-image: linear-gradient(
      to right,
      var(--primary-color) -130%,
      rgba(255, 255, 255, 0) 40%
    ),
    url("../assets/professionals-1-removebg.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 15px 90px;
  background-position: bottom;
  /* background-color: var(--primary-color); */
}

.education-image {
  background: url("../assets/books-laptops.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 15px 70px;
  background-position-y: center;
}

.track-point {
  position: absolute;
  left: -50px;
  color: var(--font-primary-color);
}

.track {
  width: 2px;
  background: var(--font-primary-color);
  height: calc(100% - 80px);
  display: flex;
  position: absolute;
  left: -31px;
  border-radius: 50px;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(30deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* experties section */

.section-header {
  text-align: center;
  margin-bottom: 20px;
}

.expertise-card h6 {
  font-size: 1.2rem;
  color: var(--font-primary-color); /* Primary color */
  margin-bottom: 10px;
}

.card-content {
  min-height: 90px;
}

.card-content {
  flex-grow: 1;
}

.footer-icons {
  display: flex;
  gap: 15px;
  overflow-x: auto !important;
}

.footer-icons img,
.footer-icons svg {
  height: 25px;
  width: 25px;
  /* background-color: white; */
  border-radius: 5px 10px;
  padding: 0.2rem;
  transition: all 0.5s ease;
}
.footer-icons img:hover {
  height: 25px;
  width: 25px;
  transition: all 0.5s ease;
}

@media (max-width: 768px) {
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.marquee {
  animation: marquee 10s linear infinite;
}

.marquee:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
  cursor: pointer;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.counter {
  animation: counter 1s forwards alternate-reverse ease-in-out;
  counter-reset: num var(--num);
}
.counter::after {
  content: counter(num);
}

@keyframes counter {
  from {
    --num: 6;
  }
  to {
    --num: 0;
  }
}

.separator {
  display: inline-block;
  width: 1px; /* Vertical separator width */
  height: 30px; /* Match icon height */
  background-color: var(--link-color);
}

.basic-gradient-right {
  padding: 3rem;
  border-radius: 30px 60px;
  background: linear-gradient(
    to left,
    var(--primary-color) -80%,
    rgba(255, 255, 255, 0) 30%
  );
}

.basic-gradient-left {
  padding: 3rem;
  border-radius: 30px 60px;
  background: linear-gradient(
    to right,
    var(--primary-color) -80%,
    rgba(255, 255, 255, 0) 30%
  );
}

.ar-resume-sticky {
  position: fixed;
  left: 0px;
  top: 25%;
  transform: translateY(10%);
  z-index: 1000;
  display: flex;
}

.resume-button {
  position: absolute;
  left: -80px;
  transform: translateX(0%);
  background: var(--primary-color);
  padding: 0.75rem 1.5rem;
  border-radius: 0 24px 24px 0;
  color: white;
  text-decoration: none;
  font-weight: 800;
  font-size: 1rem;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  white-space: nowrap;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
}

.resume-text {
  background: linear-gradient(to right, #ffffff, #f0f0f0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
  font-family: "Inconsolata", monospace;
}

.resume-arrow-trigger {
  background: var(--primary-color);
  color: white;
  padding: 0.75rem 0.5rem;
  border-radius: 0 24px 24px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  position: relative;
  z-index: 1000;
  top: 21px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .resume-arrow-trigger {
    top: 19px;
  }
}

.arrow {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  display: inline-block;
  padding-left: 0.5rem;
}

/* Hover animations */
.ar-resume-sticky:hover .resume-button {
  transform: translateX(80px);
  background: linear-gradient(45deg, var(--primary-color), #d55882);
}

.ar-resume-sticky:hover .resume-arrow-trigger {
  transform: translateX(-80px);
}

.resume-icon {
  font-size: 1.2rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .resume-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.95rem;
    font-weight: 800;
  }

  .resume-arrow-trigger {
    padding: 0.6rem 0.4rem;
  }
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .resume-arrow-trigger,
  .resume-button {
    background: var(--primary-color);
  }
}

.ar-resume-sticky:hover {
  cursor: pointer;
}
