.bar {
  margin: 25px 0;
}

.skills-bar .bar .info span {
  font-size: 1rem;
  font-weight: 500;
}

.skills-bar .bar .info {
  color: inherit;
}

.skills-bar .bar .progress-line {
  position: relative;
  height: 10px;
  background: #f0f0f0;
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}

.bar .progress-line span {
  position: absolute;
  background: var(--primary-color);
  height: 100%;
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  text-transfrom: uppercase;
  animation: animate 1s 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}

@keyframes animate {
  100% {
    transform: scaleX(1);
  }
}

.progress-line .ui-ux {
    width: 90%;
  }
.progress-line .frontend {
  width: 95%;
}
.progress-line .mobileapps {
  width: 80%;
}
.progress-line .backend {
  width: 70%;
}
.progress-line .testing {
  width: 60%;
}

.bar .progress-line span::before {
  position: absolute;
  content: "";
  height: 0;
  right: 0;
  top: -14px;
  width: 0;
  border: 7px solid transparent;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-top-style: #f0f0f0;
  border-top-color: #07374a;
}

.bar .progress-line span::after {
  position: absolute;
  right: 0;
  top: -28px;
  color: #f0f0f0;
  font-size: 12px;
  font-weight: 700;
  background: #07374a;
  padding: 1px 8px;
  border-radius: 3px;
}

.progress-line .ui-ux::after {
    content: "9";
  }

.progress-line .frontend::after {
  content: "9.5";
}
.progress-line .mobileapps::after {
  content: "8";
}
.progress-line .backend::after {
  content: "7";
}
.progress-line .testing::after {
  content: "6";
}
