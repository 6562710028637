.awards-section {
  padding: 4rem 0;
}

.awards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.award-card {
  display: flex;
  flex-direction: column;
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 30px 60px;
  padding: 2rem;
  transition: all 0.3s ease;
}

.award-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(var(--primary-color-rgb), 0.05) 0%,
    rgba(var(--primary-color-rgb), 0.02) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.award-card:hover {
  transform: translateY(-5px);
  border-color: var(--primary-color);
  box-shadow: 
    inset 0 0 20px rgba(var(--primary-color-rgb), 0.1),
    0 10px 30px rgba(var(--primary-color-rgb), 0.1);
}

.award-card:hover::before {
  opacity: 1;
}

.award-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.icon-primary {
  font-size: 3.5rem;
  color: var(--primary-color);
  padding: 0.5rem;
  background: rgba(var(--primary-color-rgb), 0.1);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.award-content {
  width: 100%;
}

.award-header {
  margin-bottom: 1rem;
}

.award-header h3 {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin: 0;
  font-weight: 600;
}

.award-date {
  font-size: 0.875rem;
  color: var(--light-grey);
  font-style: italic;
}

.award-description {
  color: var(--light-grey);
  margin-bottom: 1.25rem;
  line-height: 1.6;
}

.award-files {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.award-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.award-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: rgba(var(--primary-color-rgb), 0.05);
  color: var(--primary-color);
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.award-badge:hover {
  background: rgba(var(--primary-color-rgb), 0.1);
  transform: translateY(-2px);
}

.badge-icon {
  font-size: 1rem;
}

.download-link {
  color: var(--primary-color);
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.download-link:hover {
  opacity: 1;
  background: rgba(var(--primary-color-rgb), 0.1);
  transform: translateY(-2px);
}

.download-icon {
  font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 992px) {
  .awards-container {
    grid-template-columns: 1fr;
  }

  .award-card {
    padding: 1.5rem;
  }

  .award-header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .award-files {
    flex-direction: column;
  }
}

/* Dark Mode Adjustments */
body.dark-mode .award-card {
  background: rgba(255, 255, 255, 0.03);
}

body.dark-mode .award-card:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}



