@import './styles/LoadingSpinner.css';
/* Your other imports and styles */

.margin-0 {
  margin: 0 !important;
}

.padding-left-1 {
  padding-left: 1rem;
}
.padding-left-2 {
  padding-left: 2rem;
}
.padding-left-3 {
  padding-left: 3rem;
}
.padding-left-4 {
  padding-left: 4rem;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.padding-0 {
  padding: 0;
}

.ar-link-color {
  color: var(--link-color);
}
.ar-section-padding {
  padding: var(--navbar-padding);
}

.ar-simple-grey-color {
  color: var(--link-color);
}

.ar-light-grey {
  color: var(--light-grey);
}

.ar-flex-center {
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
}

.ar-flex-align-center {
  align-items: center;
}

.ar-flex {
  display: flex;
}

.ar-gap-10 {
  gap: 10px;
}

.ar-gap-20 {
  gap: 20px;
}

.ar-gap-30 {
  gap: 30px;
}

.ar-gap-50 {
  gap: 50px;
}

.ar-flex-arround {
  display: flex;
  justify-content: space-around;
}

.ar-flex-between {
  display: flex;
  justify-content: space-between;
}

.ar-flex-evenly {
  display: flex;
  justify-content: space-around;
}

.ar-flex-end {
  justify-content: end !important;
}

.ar-spacer-hr-sm {
  padding: 1rem 0;
  display: flex;
  justify-content: end;
}
.ar-spacer-hr-lg {
  padding: 2rem 0;
  display: flex;
  justify-content: end;
}

.ar-align-items-center {
  display: flex;
  align-items: center;
}

.ar-text-align-center {
  text-align: center;
}

.ar-color-black {
  color: var(--text-light);
}

.ar-color-primary {
  color: var(--text-primary);
}

body.light-mode .ar-color-black {
  color: var(--text-dark);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* button animation */

.btn-flip {
  opacity: 1;
  outline: 0;
  color: var(--text-light);
  line-height: 40px;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.btn-flip:hover::after {
  opacity: 1;
  border-radius: 30px 10px;
  transform: translateY(0) rotateX(0);
}

.inconsolata-family {
  font-family: "Inconsolata", monospace;
}

.bold-700 {
  font-weight: 700;
}

.bold-600 {
  font-weight: 600;
}

.bold-900 {
  font-weight: 900;
}

.opacity-half {
  opacity: 0.5;
}

.Azeret-family {
  font-family: "Azeret", monospace;
}

.btn-flip:hover::before {
  opacity: 0;
  border-radius: 40px 10px;
  transform: translateY(50%) rotateX(90deg);
}

.btn-flip::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  color: var(--button-primary-color);
  display: block;
  transition: 0.25s;
  position: absolute;
  background: transparent;
  content: attr(data-back);
  border: 1px solid var(--button-primary-color);
  border-radius: 40px 10px;
  transform: translateY(-50%) rotateX(90deg);
}

.btn-icon {
  color: var(--button-primary-color);
  border-radius: 10px 20px;
  padding: 0.25rem 0.5rem;
  transition: all 0.5s ease;
}

.btn-icon:hover {
  background: var(--button-primary-color);
  color: var(--text-light);
  transform: translateY(-5px);
  transition: all 0.5s ease;
  padding: 10px 15px;
}

.normal-btn {
  background: var(--button-primary-color);
  color: var(--text-light);
  border-radius: 10px 40px;
  padding: 5px 20px;
  border: 1px solid var(--button-primary-color);
}

.underline-button {
  position: relative;
  color: var(--button-primary-color);
  border-radius: 10px 40px;
  padding: 10px 30px;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
  background: transparent;
  border: none;
  text-decoration: none !important;
}

.underline-button::before,
.underline-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid var(--button-primary-color);
  top: 0;
  left: 0;
  border-radius: 10px 40px;
  transition: transform 0.5s ease-in-out;
}

.underline-button::before {
  border-top: none;
  border-left: none;
  transform: translateX(-100%) translateY(-100%);
}

.underline-button::after {
  border-bottom: none;
  border-right: none;
  transform: translateX(100%) translateY(100%);
}

.underline-button:hover {
  color: var(--button-primary-color);
  border: var(--button-primary-color);
}

.underline-button:hover::before {
  transform: translateX(0) translateY(0);
}

.underline-button:hover::after {
  transform: translateX(0) translateY(0);
}


.transparent-bg-btn {
  background: transparent;
  border: none;
}
.underline:hover {
  text-decoration: underline;
}

.btn-flip::before {
  top: 0;
  left: 0;
  opacity: 1;
  color: var(--text-light);
  display: block;
  padding: 0 30px;
  line-height: 40px;
  transition: 0.25s;
  position: relative;
  background: var(--button-primary-color);
  content: attr(data-front);
  border-radius: 10px 40px;
  transform: translateY(0) rotateX(0);
}

.circle-profile-image {
  height: 100px;
}

.battle-net-icon {
  font-size: 25px !important;
  color: var(--button-primary-color);
}

.modal-header {
  border-bottom: 1px solid var(--button-primary-color) !important ;
}

.modal-logo {
  height: 50px;
  border-radius: 5px 15px;
      margin: 0.25rem 0.25rem;
}

/* .ar-section-heading {
  border-bottom: 3px solid var(--button-primary-color);
  margin-bottom: 1rem !important;
  border-color: linear-gradient(90deg, var(--token-bdd95f3b-9ae9-4f42-9b2d-14f79dd05086, #fffcf2) 0%, rgb(168, 36, 91) 32.8125%, rgb(133, 2, 63) 100%);
} */

.ar-section-heading {
  position: relative;
  display: inline-block;
  padding-bottom: 5px; /* Adjust thickness */
}

.ar-section-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px; /* Border thickness */
  background: linear-gradient(
    90deg,
    var(--button-primary-color),
    var(--light-primary-gradient-color)
  ); /* Gradient from primary pink to a lighter shade */
  border-radius: 2px;
}

.card-col {
  margin-bottom: 25px;
}

.expertise-card {
  background: rgba(var(--primary-color-rgb), 0.05);
  border: 1px solid var(--border-color);
  border-radius: 20px 50px !important;
  padding: 2rem;
  transition: all 0.3s ease;
  height: 100%;
}

.expertise-card:hover {
  border-color: var(--primary-color);
}

.modal.show {
  opacity: 1;
  transition: all 1s ease;
}

.padding-top-0 {
  padding-top: 0 !important;
}


._btn_1u49a_160 {
  background-color: var(--background-primary) !important;
}
._minimize_1u49a_263 {
  background-color: var(--background-primary) !important;

}

._ellipsis_1u49a_243 {
  color: #0b0b0d !important;
}

/* Add this at the top of your App.css */
@import './styles/Modal.css';

/* Remove any existing modal styles from App.css */