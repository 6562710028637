/* Extra Small Screens */
@media (max-width: 599px) {
  .expertise-card {
    height: 100%;
    border-radius: 20px 50px !important;
  }

  .setting-div {
    top: 16vh;
  }
  .setting-div-2 {
    top: 25vh;
  }
  .inconsolata-family {
    font-size: 14px !important;
  }
  p {
    font-size: 14px !important;
  }
  .modal-content {
    height: 90vh;
  }
  .ar-spacer-hr-sm {
    padding: 0.5rem;
  }
  .ar-spacer-hr-lg {
    padding: 1rem;
  }
  .ar-primary-text {
    font-size: 2rem !important;
    text-align: center !important;
  }
  .hello-text {
    text-align: center !important;
  }
  .profile-center {
    text-align: center;
  }

  .background-image-amrit {
    background-position: 0vw 0px !important;
  }
  .font-size-hero {
    font-size: 32px !important;
  }
  .background-image-amrit::before {
    background: rgba(0, 0, 0, 0.4) !important;
  }

  .navbar {
    margin: 0 auto;
  }
  .web-view {
    display: none;
  }
  .first-section-img-div {
    justify-content: center;
    display: flex;
    overflow: hidden;
  }
  .scroll-top-btn {
    /* z-index: 0 !important; */
  }

  .second-section-text {
    padding: 0.5rem;
    text-align: center !important;
    justify-content: center;
    display: flex;
  }
  .ar-flex-end {
    padding: 1rem;
    justify-content: center !important;
  }
  .ar-section-heading {
    font-size: 1.5rem !important;
    width: 100% !important;
  }

  .track-point {
    left: -27px !important;
  }

  .track {
    left: -7.5px !important;
  }

  .footer-bottom-text {
    text-align: center;
    padding: 1rem;
  }

  .footer-bottom-icons {
    justify-content: center !important;
  }
  .scrolled-bg {
    border-radius: 50px 100px;
    width: 90%;
  }
  .contact-me {
    padding-right: 0 !important;
  }
}
/* Small Screens */
@media (min-width: 600px) and (max-width: 1080px) {
  .footer-bottom-icons {
    justify-content: center !important;
  }
  .setting-div {
    top: 16vh;
  }
  .setting-div-2 {
    top: 25vh;
  }
  .font-size-hero {
    font-size: 64px !important;
  }

  .modal-content {
    height: 90vh;
  }
  .contact-me {
    padding-right: 0 !important;
  }
  .background-image-amrit {
    background-position: 0vw 0px !important;
  }

  .background-image-amrit::before {
    background: rgba(0, 0, 0, 0.6) !important;
  }

  .profile-center {
    text-align: center;
  }

  .scrolled-bg {
    border-radius: 50px 100px;
    width: 90%;
  }
  .ar-spacer-hr-sm {
    padding: 0.5rem;
  }
  .ar-spacer-hr-lg {
    padding: 1rem;
  }
  .ar-primary-text {
    font-size: 2rem !important;
  }
  .navbar {
    margin: 0 auto;
  }
  .web-view {
    display: none;
  }

  .first-section-img-div {
    justify-content: center;
    display: flex;
    overflow: hidden;
    text-align: center !important;
  }
  .second-section-text {
    padding: 0.5rem;
    text-align: center !important;
    justify-content: center;
    display: flex;
  }

  .ar-flex-end {
    padding: 1rem;
    justify-content: center !important;
  }
  .ar-section-heading {
    font-size: 1rem !important;
    width: 100% !important;
  }

  .track-point {
    left: -27px !important;
  }

  .track {
    left: -7.5px !important;
  }
  .footer-bottom-text {
    text-align: center;
    padding: 1rem;
  }
}

/* Medium Screens */
@media (min-width: 1080px) and (max-width: 1399px) {
  .footer-mobile,
  .mob-view {
    display: none;
  }
  

  .font-size-hero {
    font-size: 48px !important;
  }

  .footer-bottom-icons {
    justify-content: end;
  }

  .footer-bottom-text {
    text-align: end;
  }
  /* Navbar nav link animations */

  nav {
    position: relative;
  }
  nav li {
    text-decoration: none;
    position: relative;
    z-index: 1;
    display: inline-block;
    text-align: center;
  }
  nav .animation {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 0;
    background: var(--border-color);
    border-radius: 20px 70px;
    transition: all 0.7s ease 0s;
  }
  nav li:nth-child(1) {
    width: 100px;
  }
  nav li,
  li:nth-child(1):hover ~ .animation {
    width: 100px;
    left: 0;
  }
  nav li:nth-child(2) {
    width: 120px;
  }
  nav li:nth-child(2):hover ~ .animation {
    width: 120px;
    left: 100px;
  }
  nav li:nth-child(3) {
    width: 120px;
  }
  nav li:nth-child(3):hover ~ .animation {
    width: 120px;
    left: 220px;
  }
  nav li:nth-child(4) {
    width: 120px;
  }
  nav li:nth-child(4):hover ~ .animation {
    width: 120px;
    left: 340px;
  }
}

/* Large Screens */
@media (min-width: 1400px) {
  .footer-mobile,
  .mob-view {
    display: none;
  }
  .setting-div {
    top: 35px;
  }

  .setting-div-2 {
    top: 100px;
  }

  .font-size-hero {
    font-size: 64px !important;
  }

  .ar-step-count {
    font-weight: 800;
    font-size: 96px;
    color: transparent;
    background: linear-gradient(
      90deg,
      var(--button-primary-color),
      var(--light-primary-gradient-color)
    );
    -webkit-background-clip: text;
    background-clip: text;
  }

  .footer-bottom-icons {
    justify-content: end;
  }
  .footer-bottom-text {
    text-align: end;
  }
  /* Navbar nav link animations */

  nav {
    position: relative;
  }
  nav li {
    text-decoration: none;
    position: relative;
    z-index: 1;
    display: inline-block;
    text-align: center;
  }
  nav .animation {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 0;
    background: var(--border-color);
    border-radius: 20px 70px;
    transition: all 0.7s ease 0s;
  }
  nav li:nth-child(1) {
    width: 100px;
  }
  nav li,
  li:nth-child(1):hover ~ .animation {
    width: 100px;
    left: 0;
  }
  nav li:nth-child(2) {
    width: 120px;
  }
  nav li:nth-child(2):hover ~ .animation {
    width: 120px;
    left: 100px;
  }
  nav li:nth-child(3) {
    width: 120px;
  }
  nav li:nth-child(3):hover ~ .animation {
    width: 120px;
    left: 220px;
  }
  nav li:nth-child(4) {
    width: 120px;
  }
  nav li:nth-child(4):hover ~ .animation {
    width: 120px;
    left: 340px;
  }
  nav li:nth-child(5) {
    width: 120px;
  }
  nav li:nth-child(5):hover ~ .animation {
    width: 120px;
    left: 470px;
  }
}
.ar-title-btn{
color: #fff;
cursor: pointer;
}
/* High Resolution Displays */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}
