.admin-dashboard {
  padding: 1.25rem 1rem; /* Increased top/bottom padding */
  height: calc(100vh - 80px); /* Adjust based on your navbar height */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Increased gap between elements */
}

.table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* Important for proper scrolling */
}

.table-wrapper::-webkit-scrollbar {
  width: 12px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #363134;
  border-radius: 10px;
  border: 3px solid var(--background-dark);
  transition: all 1s ease;
}

/* Light mode styles */
body.light-mode .table-wrapper::-webkit-scrollbar-thumb {
  background-color: #b2a0a0;
  border-radius: 10px;
  border: 3px solid var(--background-light);
  cursor: grab;
  transition: all 1s ease;
}

/* Add horizontal scrollbar styles for the table */
.table-wrapper::-webkit-scrollbar:horizontal {
  height: 12px;
}

.table-wrapper::-webkit-scrollbar-thumb:horizontal {
  background-color: #363134;
  border-radius: 10px;
  border: 3px solid var(--background-dark);
  transition: all 1s ease;
}

.light-mode .table-wrapper::-webkit-scrollbar-thumb:horizontal {
  background-color: #b2a0a0;
  border-radius: 10px;
  border: 3px solid #ebe8e4;
  cursor: grab;
  transition: all 1s ease;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem; /* Increased padding left and right */
  background: linear-gradient(145deg, var(--primary-color), var(--light-primary-gradient-color));
  border-radius: 12px;
  color: white;
  margin: 0 0.5rem; /* Add margin to match container padding */
  box-shadow: 0 2px 10px rgba(213, 88, 130, 0.2);
}

.dashboard-title {
  display: flex;
  align-items: center;
  gap: 1.25rem; /* Increased gap */
}

.dashboard-title h2 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.total-entries {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.35rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  backdrop-filter: blur(5px);
}

.dashboard-controls {
  display: flex;
  gap: 1.25rem;
  align-items: center;
}

.items-per-page {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.9rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1.2rem;
  border-radius: var(--border-radius-md);
  backdrop-filter: blur(5px);
}

.items-select {
  padding: 0.4rem 1.2rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: var(--border-radius-sm);
  background: transparent;
  color: var(--text-light);
  font-size: 0.9rem;
  cursor: pointer;
  height: 36px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='white' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  padding-right: 2.5rem;
}

body.light-mode .items-select {
  color: var(--text-dark);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='black' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
}

.items-select option {
  background: var(--background-dark);
  color: var(--text-light);
}

body.light-mode .items-select option {
  background: var(--background-light);
  color: var(--text-dark);
}

/* Label styling */
.items-per-page label {
  font-weight: 500;
  color: white;
  margin-right: 0.5rem;
}

.table-wrapper {
  flex: 1;
  overflow: auto;
  border-radius: 8px;
  border: 1px solid rgba(213, 88, 130, 0.1);
  background: var(--background-dark);
  margin-bottom: 0.5rem;
  transition: background-color 1s ease-in-out, 
              border-color 0.25s ease,
              box-shadow 0.25s ease;
}

body.light-mode .table-wrapper {
  background: var(--background-light);
  transition: background-color 1s ease-in-out, 
              border-color 0.25s ease,
              box-shadow 0.25s ease;
}

.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.85rem;
}

/* Mobile-specific table styles */
@media (max-width: 767px) {
  .data-table td {
    padding: 0.6rem 0.8rem;
    font-size: 0.85rem;
  }

  .message-preview {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-btn {
    padding: 0.4rem 0.6rem;
    font-size: 0.8rem;
  }

  .action-btn span {
    display: none; /* Hide text, show only icon on mobile */
  }
}

.data-table th {
  position: sticky;
  top: 0;
  background: var(--primary-color);
  color: white;
  padding: 1.2rem 0.8rem; /* Increased padding, especially vertical */
  text-align: left;
  font-weight: 500;
  font-size: 0.95rem; /* Slightly larger font */
  letter-spacing: 0.5px;
  z-index: 1;
  transition: background-color 1s ease-in-out, 
              color 1s ease;
  white-space: nowrap;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

/* First header cell rounded left corner */
.data-table th:first-child {
  padding-left: 1.5rem; /* More padding on the left */
  border-top-left-radius: var(--border-radius-lg);
}

/* Last header cell rounded right corner */
.data-table th:last-child {
  padding-right: 1.5rem; /* More padding on the right */
  border-top-right-radius: var(--border-radius-lg);
}

.data-table td {
  padding: 1rem 0.8rem; /* Increased padding to match header scale */
  border-bottom: 1px solid rgba(213, 88, 130, 0.1);
  color: var(--text-light);
  transition: background-color 1s ease-in-out, 
              color 1s ease,
              border-color 0.25s ease;
}

.data-table td:first-child {
  padding-left: 1.5rem;
}

.data-table td:last-child {
  padding-right: 1.5rem;
}

body.light-mode .data-table td {
  color: var(--text-dark);
  transition: background-color 1s ease-in-out, 
              color 1s ease,
              border-color 0.25s ease;
}

.entry-number {
  font-weight: 500;
  color: var(--primary-color);
}

.message-preview {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body.light-mode .message-preview {
  color: var(--text-dark);
}

.view-more-btn {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  padding: 0.2rem;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.view-more-btn:hover {
  opacity: 1;
}

.action-btn {
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  border: none;
  background: var(--primary-color);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.8rem;
  transition: background-color 1s ease-in-out, 
              color 1s ease,
              border-color 0.25s ease,
              opacity 0.25s ease;
}

.action-btn.delete {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.1);
}

.action-btn.delete:hover {
  background: rgba(220, 53, 69, 0.2);
}

.action-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.action-btn i {
  font-size: 1.2rem;
}

.action-btn:hover {
  background: rgba(220, 53, 69, 0.1);
}

/* Pagination Styles */
.pagination-container {
  padding: 0.5rem;
  background: transparent;
  border-top: 1px solid rgba(213, 88, 130, 0.1);
}

.pagination-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.pagination-stats {
  background: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
}

.pagination {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

/* Page number buttons */
.page-btn {
  min-width: 36px;
  height: 36px;
  padding: 0 0.75rem;
  border: 1px solid var(--primary-color);
  background: transparent;
  color: var(--primary-color);
  border-radius: var(--border-radius-sm);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

/* Active page */
.page-btn.active {
  background: var(--primary-color);
  color: white;
  font-weight: 500;
}

/* Previous/Next buttons base styles */
.page-nav-btn {
  min-width: 100px;
  padding: 0 1rem;
  background: var(--primary-color);
  color: white;
  gap: 0.5rem;
  font-weight: 500;
}

/* Hover states */
.page-btn:not(.active):hover {
  background: var(--primary-color);
  color: white;
  transform: translateY(-2px);
}

.page-nav-btn:hover:not(:disabled) {
  background: var(--light-primary-gradient-color);
  transform: translateY(-2px);
}

/* Disabled state */
.page-btn:disabled,
.page-nav-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #ccc;
  background: #f5f5f5;
  color: #888;
  transform: none;
}

/* Icons in prev/next buttons */
.page-nav-btn svg {
  font-size: 0.8rem;
}

@media (max-width: 767px) {
  .pagination {
    gap: 0.3rem;
  }

  .page-btn {
    min-width: 32px;
    height: 32px;
    font-size: 0.85rem;
  }

  .page-nav-btn {
    min-width: 32px;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-nav-btn span {
    display: none;
  }

  .page-nav-btn svg {
    margin: 0;
    font-size: 0.85rem;
  }
}

/* Quick Jump Section */
.quick-jump {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1rem;
}

.quick-jump input {
  width: 60px;
  height: 36px;
  padding: 0.4rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-sm);
  text-align: center;
  font-size: 0.9rem;
  background: transparent;
  color: var(--text-light);
  transition: background-color 1s ease-in-out, 
              color 1s ease,
              border-color 0.25s ease;
}

body.light-mode .quick-jump input {
  color: var(--text-dark);
}

.quick-jump button {
  height: 36px;
  padding: 0 1.2rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .admin-dashboard {
    height: calc(100vh - 60px);
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .dashboard-title h2 {
    font-size: 1.2rem;
  }

  .data-table {
    font-size: 0.75rem;
  }

  .data-table th,
  .data-table td {
    padding: 0.4rem;
  }

  .hide-mobile {
    display: none;
  }

  .message-preview {
    max-width: 120px;
  }

  .pagination-info {
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .page-btn {
    min-width: 28px;
    height: 28px;
    font-size: 0.75rem;
  }

  .page-nav-btn {
    min-width: 60px;
  }

  .quick-jump {
    font-size: 0.75rem;
  }

  .action-btn span {
    display: none;
  }
}

/* Tabs styling */
.tabs-container {
  display: flex;
  gap: 0.75rem;
  margin: 0 0.5rem; /* Match header margin */
  padding: 0.25rem 0;
}

.tab-button {
  padding: 0.5rem 1.25rem;
  border: 1px solid var(--primary-color);
  background: transparent;
  color: var(--primary-color);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.9rem;
  font-weight: 500;
  transition: background-color 1s ease-in-out, 
              color 1s ease,
              border-color 0.25s ease;
}

.tab-button.active {
  background: var(--primary-color);
  color: white;
  box-shadow: 0 2px 8px rgba(213, 88, 130, 0.3);
}

.tab-button:hover:not(.active) {
  background: rgba(213, 88, 130, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-header {
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    flex-direction: column;
    gap: 0.75rem;
  }

  .dashboard-title {
    gap: 1rem;
  }

  .dashboard-title h2 {
    font-size: 1.2rem;
  }

  .total-entries {
    padding: 0.25rem 0.75rem;
    font-size: 0.8rem;
  }

  .items-per-page {
    padding: 0.25rem 0.75rem;
    font-size: 0.8rem;
  }

  .items-select {
    font-size: 0.8rem;
  }
}

/* Define a consistent border radius with increased values */
:root {
  --border-radius-sm: 16px;
  --border-radius-md: 24px;
  --border-radius-lg: 30px;
}

/* Table wrapper */
.table-wrapper {
  border-radius: var(--border-radius-lg);
}

/* Action buttons - increase horizontal padding */
.action-btn {
  padding: 0.3rem 1.2rem;  /* 0.3rem top/bottom, 1.2rem left/right */
  border-radius: var(--border-radius-sm);
}

/* Tab buttons - increase horizontal padding */
.tab-button {
  padding: 0.5rem 2rem;  /* 0.5rem top/bottom, 2rem left/right */
  border-radius: var(--border-radius-md);
}

/* Pagination buttons - increase horizontal padding */
.page-btn {
  min-width: 32px;
  height: 32px;
  padding: 0 1rem;  /* 0 top/bottom, 1rem left/right */
  border-radius: var(--border-radius-sm);
}

/* Page navigation buttons (Previous/Next) */
.page-nav-btn {
  min-width: 100px;  /* Increased from 80px */
  padding: 0 1.5rem;  /* 0 top/bottom, 1.5rem left/right */
}

/* Quick jump input */
.quick-jump input {
  border-radius: var(--border-radius-sm);
}

/* Quick jump button */
.quick-jump button {
  padding: 0.4rem 1.5rem;
  border-radius: var(--border-radius-sm);
}

/* Items select dropdown */
.items-select {
  border-radius: var(--border-radius-sm);
}

/* Pagination stats container */
.pagination-stats {
  border-radius: var(--border-radius-sm);
}

/* Any other form inputs or selects */
input[type="text"],
input[type="number"],
input[type="search"],
select {
  border-radius: var(--border-radius-sm);
}

/* Table first and last cells in each row for rounded corners */
.data-table tr:first-child th:first-child {
  border-top-left-radius: var(--border-radius-lg);
}

.data-table tr:first-child th:last-child {
  border-top-right-radius: var(--border-radius-lg);
}

.data-table tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius-sm);
}

.data-table tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius-sm);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  :root {
    --border-radius-sm: 14px;
    --border-radius-md: 20px;
    --border-radius-lg: 26px;
  }
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .action-btn {
    padding: 0.3rem 1rem;
  }

  .tab-button {
    padding: 0.5rem 1.5rem;
  }

  .page-nav-btn {
    min-width: 90px;
    padding: 0 1rem;
  }

  .quick-jump button {
    padding: 0.4rem 1.2rem;
  }

  .pagination {
    gap: 0.3rem;
  }

  .page-btn {
    min-width: 32px;
    height: 32px;
    font-size: 0.85rem;
  }

  /* Reduced width for prev/next buttons on mobile */
  .page-nav-btn {
    min-width: 32px; /* Matches other page buttons */
    width: 32px; /* Fixed width */
    height: 32px; /* Square shape */
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-nav-btn span {
    display: none;
  }

  /* Center the icons */
  .page-nav-btn svg {
    margin: 0;
    font-size: 0.85rem;
  }

  .ellipsis {
    padding: 0 0.3rem;
  }
}
